import React, { useState } from 'react';
// import { Link } from 'gatsby';
import styles from './Header.module.scss';
import Logo from '../images/ismailani_logo.svg';

const Header = () => {
  // const collapsedRef = React.useRef();
  const burgersRef = React.useRef();
  // const ulRef = React.useRef();

  return (
    <div className="custom-navbar">
      <h4 className="header-title">
        <a href="/" alt="">
          <img src={Logo} alt="ismailani-logo" className="site-logo"></img>
        </a></h4>
      <button
        type="button"
        className={`${styles.burgers} burgers`}
        ref={burgersRef}
        onClick={() => {
          document.querySelector('.sideMenu').classList.toggle('open');
        }}
      >
        <React.Fragment>
          <div className={`${styles.burger} burger`} />
          <div className={`${styles.burger} burger`} />
          <div className={`${styles.burger} burger`} />
        </React.Fragment>
      </button>
    </div>
  );
};

export default Header;
