import React from 'react';
import { Link } from 'gatsby';
import styles from './Footer.module.scss';

const Footer = ({ noBg }) => (
  <div className={`${styles.footer} ${noBg && styles.noBg} d-flex justify-content-center align-items-center text-center`}>
    <div className="d-flex justify-content-center align-items-center text-center">
      <p>© 2021
      <Link className="footer-link" to="/">Ismailani Constructions.</Link>
      All Rights Reserved.</p>
    </div>
  </div>
);

export default Footer;
