import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import MapSection from './MapSection';
import SideMenu from './sideMenu';

import './global.scss';
import styles from './Layout.module.scss';

const config = require('../../gatsby-config');

const Layout = ({
  title = '', children, hasMarginForHeader = false, noMap, noFooter,
}) => {
  const titleReduce = title ?
    [title, config.siteMetadata.title].join(' | ') :
    config.siteMetadata.title;

  return (
    <>
      <Helmet>
        <title>{titleReduce}</title>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1"
          crossOrigin="anonymous"
        />

        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap" rel="stylesheet" />

        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
          integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
          crossOrigin=""
        />

        <meta
          name="keywords"
          content="Γκαναϊτ, εκτοξευομενο σκυρόδεμα, gunite, ενισχύσεις κτιρίου με γκαναϊτ, γκαναϊτ Εύβοια, ξηρό γκαναϊτ, επισκευές κτιρίου, αναπαλαίωση κτιρίου,
          ενοικίασης σκαλωσιων, ενοικίαση ΣΚΑΛΩΣΙΩΝ, σκαλωσιέσ, σκαλωσιέσ Εύβοια, ικριωματα, τοποθέτηση σκαλωσιων, Θερμοπροσοψη, Θερμομονωση,Θερμοπρωσοψη ευβοια,
          Θερμοπροσοψη τιμές, μόνωση, Θερμοπροσοψη Χαλκίδα, εξωτερική Θερμοπροσοψη, εξοικονομω κατ οικον, Θερμοπροσοψη τιμές, Θερμοπροσοψη κτιρίου, 
          Θερμοπροσοψη isomat, σοβατισματα, Χτισιματα, επιχρησματα, Θερμοπροσοψη σοβάς, κόλα πλέγμα, τεχνικές λύσεις, αμμοβολη, αμμοβολες, Υδροβολη, 
          υδροβολες, υδροαμμοβολη, αρμολογηση πετρασ, καθάρισμα πέτρας , βαφές, ελαιοχρωματισμοι, ελαιοχρωματισμοι ευβοια, ελαιοχρωματισμοι Χαλκίδα"
        />
      </Helmet>

      <Header />
      <SideMenu />

      <main className={`${hasMarginForHeader && styles.mainMarginTop}`}>
        {children}
      </main>

      {!noMap && <MapSection /> }
      {!noFooter && <Footer /> }
    </>
  );
};

export default Layout;
