import React from 'react';
import L from 'leaflet';
import styles from './Map.module.scss';
import customMarkerIcon from '../images/custom-marker.jpg';

const Map = ({ fullSize }) => {
  React.useEffect(() => {
    let map = L.map('map', { zoomControl: true, attributionControl: false }).setView([38.396142, 24.044876], 16);

    const googleSat = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    });

    googleSat.addTo(map);

    const LeafIcon = L.Icon.extend({
      options: {
        iconSize: [30, 40],
        iconAnchor: [16, 42],
        popupAnchor: [-2, -40],
      },
    });

    const customMarker = new LeafIcon({
      iconUrl: customMarkerIcon,
    });

    // { icon: customMarker }
    L.marker([38.396142, 24.044876], { icon: customMarker }).addTo(map)
      .bindPopup('Ismailani')
      .openPopup();

    return () => {
      map.remove();
      map.off();
      map = null;
    };
  }, []);

  return (
    <div id="map" className={fullSize ? styles.mapFull : styles.mapContainer} />
  );
};

export default Map;
