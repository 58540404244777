module.exports = {
  siteMetadata: {
    title: 'Ismailani - Constructions',
    siteUrl: 'https://ismailani.gr',
    description: 'Blazing fast modern site generator for React',
  },
  plugins: [
    'gatsby-plugin-sass',
    'gatsby-plugin-sharp',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sitemap',
    'gatsby-plugin-offline',
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        background_color: '#5d0c1d',
        display: 'standalone',
        // icon: '',
        name: 'Ismailani - Construction',
        short_name: 'Ismailani',
        start_url: '/',
        theme_color: '#9a825f',
        icons: [
        ],
      },
    },
    'gatsby-transformer-sharp',
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'images',
        path: './src/images/',
      },
      __key: 'images',
    },
    {
      resolve: 'gatsby-plugin-anchor-links',
      options: {
        offset: -100,
      },
    },
    {
      resolve: 'gatsby-plugin-react-leaflet',
      options: {
        linkStyles: false, // Enables loading stylesheets via CDN
      },
    },
  ],
};
