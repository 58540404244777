import React from 'react';

const SideMenu = () => {

    const closeSideMenu = () => {
        document.querySelector('.sideMenu').classList.remove('open');
    }

    return (
        <div className="sideMenu">
            <div className="content"><a onClick={closeSideMenu} href="/">ΑΡΧΙΚΗ</a></div>
            <div className="content"><a onClick={closeSideMenu} href="/album">ΑΛΜΠΟΥΜ</a></div>
            <div className="content"><a onClick={closeSideMenu} href="/#whoWeAre">ΠΟΙΟΙ ΕΙΜΑΣΤΕ</a></div>
            <div className="content"><a onClick={closeSideMenu} href="/#services">ΥΠΗΡΕΣΙΕΣ</a></div>
            <div className="content"><a onClick={closeSideMenu} href="#mapSection">ΕΠΙΚΟΙΝΩΝΙΑ</a></div>
        </div>
    )
}
 
export default SideMenu;
