import React from 'react';
import Map from './Map';
import styles from './MapSection.module.scss';

const MapSection = () => (
  <section className={`${styles.mapSection} d-flex justify-content-center align-items-center flex-column`} id="mapSection">
    <div className="fullSize row">
      <div className="fullHeight d-flex align-items-center justify-content-center col-xl-6 col-l-6 col-m-12">
        <Map />
      </div>
      <div className={`${styles.info} d-flex flex-column align-items-center justify-content-center flex-column col-xl-6 col-l-6 col-m-12`}>
        <div className={styles.logo}>
          {/* <img src={logo} loading="lazy" alt="logo" /> */}
        </div>
        <p>Aliveri, Euvoia, 34500 Greece</p>

        <div className="footer-content">

          <div className="space-between">
            <div>
              <p>Email:</p>
            </div>
            <div>
              <p><a className="contact-link-gold" href="mailto:contact@ismailanji.com">ismailanji@gmail.com</a></p>
            </div>
          </div>

          <div className="space-between">
            <div>
              <p>Τηλέφωνο:</p>
            </div>
            <div>
              <p><a className="contact-link-gold" href="tel:2223024453">2223024453</a></p>
            </div>
          </div>
          <div className="space-between">
            <div>
              <p>Κινητό:</p>
            </div>
            <div>
              <p><a className="contact-link-gold" href="tel:6970011578">6970011578</a></p>
            </div>
          </div>
          <div className="space-between">
            <div>
              <p>Youtube:</p>
            </div>
            <div>
              <p><a className="contact-link-gold" href="https://www.youtube.com/channel/UC0LWj8ZtZHVpoiZHVtD7YHQ">Ismailani V</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default MapSection;
